import { template as template_ea43284f7b2342199fab55ccc7cd4179 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ea43284f7b2342199fab55ccc7cd4179(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
