import { template as template_8c0ade29102e41428afa7674ecc36dd6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8c0ade29102e41428afa7674ecc36dd6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
