import { template as template_329222dd7d854a02960d249c3e2f8914 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_329222dd7d854a02960d249c3e2f8914(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
